
import { UserPresetCampaignType } from '@/types/preset-management/UserPresetCampaignType';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'prepareViewer',

  setup() {
    const store = useStore();
    const router = useRouter();

    onBeforeMount(async () => {
      await store.dispatch('presetFilter/loadShareCampaigns');
      const campaignShareWithMeList: UserPresetCampaignType[] =
        store.state.presetFilter.campaignShareWithMes;

      if (campaignShareWithMeList.length > 0) {
        router.push('/custom-campaign/' + campaignShareWithMeList[0].id);
      } else {
        router.push('/welcome');
      }
    });
  },
};
